@import "./variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1500px;
  margin: 0px auto;
  width: 100%;
}

.padding-container {
  padding-inline: 130px;
}

.scrolled {
  background-color: $white-color; /* Change the background color as desired */
  /* Add other styles for the scrolled state */
  transition: background-color 0.05s ease-in-out;
}

.black-text {
  color: #000000;
  width: 100%;
  font-size: 14px;
}

.grey-text {
  color: $grey-color;
}

.transparent-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: $white-color;
  .container {
    display: flex;
    width: 100%;
    padding-block: 20px;
    padding-bottom: 30px;
    align-items: flex-end;
    justify-content: space-between;
    align-items: center;

    gap: 30px;

    img {
      width: 162px;
    }
    nav {
      ul {
        display: flex;
        justify-content: center;
        gap: 30px;
        align-items: center;

        li {
          .link {
            color: #000000;
            font-size: 14px;
          }
        }
      }
      .hamburger-container,
      .close-hamburger-container {
        padding: 10px;
        border: 2px solid $grey-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        display: none;

        .hamburger {
          font-size: 24px;
          color: $grey-color;
        }
        .close-hamburger {
          font-size: 24px;
          color: $grey-color;
        }
      }
    }
  }
}

.general-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: $white-color;
  .container {
    display: flex;
    width: 100%;
    padding-block: 20px;
    padding-bottom: 30px;
    align-items: center;
    justify-content: space-between;

    gap: 30px;

    img {
      width: 162px;
    }
    nav {
      ul {
        display: flex;
        justify-content: center;
        gap: 30px;
        align-items: center;

        li {
          .link {
            color: #000000;
            font-size: 14px;
          }
        }
      }

      .hamburger-container,
      .close-hamburger-container {
        padding: 10px;
        border: 2px solid $grey-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        display: none;

        .hamburger {
          font-size: 24px;
          color: $grey-color;
        }
        .close-hamburger {
          font-size: 24px;
          color: $grey-color;
        }
      }
    }
  }
}

.facilities-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  row-gap: 50px;
  width: 100%;
  .facilities-card {
    display: flex;
    flex-direction: column;
    gap: 30px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    border-radius: 8px;

    &:hover {
      -webkit-box-shadow: -7px 30px 27px -26px rgba(0, 0, 0, 0.54);
      -moz-box-shadow: -7px 30px 27px -26px rgba(0, 0, 0, 0.54);
      box-shadow: -7px 30px 27px -26px rgba(0, 0, 0, 0.54);
      .facilities-img-container {
        img {
          transform: scale(1.4);
        }
      }
    }

    .facilities-img-container {
      border-radius: 8px;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      height: 200px;

      // &:hover{
      //     .img-overlay{
      //         top: 0;
      //     }
      // }
      img {
        border-radius: 8px;
        object-fit: cover;
        object-position: top;

        width: 100%;
        height: 100%;
        transition: all 0.4s ease-in-out;
      }

      .show-bottom {
        object-position: bottom;
      }

      .img-overlay {
        position: absolute;
        top: 300px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(237, 33, 94, 0.8);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: $white-color;
        transition: all 0.4s ease;
        padding: 10px;

        h4 {
          font-weight: 500;
          font-size: 20px;
        }
      }
    }

    .facilities-text-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 20px;

      span {
        color: $grey-color;
      }
    }
  }
}

.facility-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .popup-content {
    background-color: $white-color;
    z-index: 1000;
    padding: 20px;
    position: relative;
    padding-top: 20px;
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      font-weight: 400;
      font-size: 24px;
      margin-right: 60px;
      text-transform: capitalize;
    }

    .loader {
      color: $primary-color;
    }
    a {
      color: $white-color;
      text-decoration: none;
      transition: all 0.4s ease;
      background-color: $primary-color;
      border: 1px solid $primary-color;
      padding: 10px 20px;
      display: block;
      width: fit-content;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        color: $primary-color;
        background-color: $white-color;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
    }
    hr {
      margin-top: 10px;
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    padding-inline: 15px;
    font-size: 40px;
    outline: none;
    border: none;
    transition: all 0.4s ease;

    &:hover {
      background-color: rgba(149, 11, 11, 0.831);
      color: $white-color;
    }
  }
}

.navOpen {
  top: 85px !important;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 1400px) {
  .general-header,
  .transparent-header {
    .container {
      nav {
        ul {
          li {
            .link {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.border-btn-link {
  color: $primary-color;
  max-width: 300px;
  border: 2px solid $primary-color;
  display: inline-block;
  padding: 20px 50px;

  font-weight: 600;
  width: fit-content;
}
.white-bg-link {
  color: $primary-color;
  max-width: 300px;
  background-color: $white-color;
  display: inline-block;
  padding: 20px 50px;
  border-radius: 8px;
  font-weight: 600;
}
.pink-bg-link {
  max-width: 300px;
  background-color: $primary-color;
  padding: 20px 50px;
  display: inline-block;
  border-radius: 8px;
  font-weight: 600;

  span {
    color: $white-color;
  }
}
body {
  font-family: $font-family;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.testimonial {
  background-color: $primary-color;
  .container {
    display: flex;
    flex-direction: column;
    padding-block: 100px;
    color: $white-color;
    gap: 50px;

    h1 {
      font-weight: 400;
      font-size: 48px;
      text-align: center;
    }

    .testimonial-cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;

      .testimonial-card {
        display: flex;
        gap: 20px;
        .quote-icon-container {
          .quote-icon {
            font-size: 50px;
          }
        }

        .testimonial-content {
          display: flex;
          flex-direction: column;
          gap: 40px;
          p {
            line-height: 30px;
          }
        }
      }
    }
  }
}

.health-partner {
  .container {
    padding-block: 100px;

    .health-main-container {
      background-color: rgba(255, 247, 249, 1);
      overflow: hidden;

      padding: 50px 40px;
      border-radius: 16px;
      display: flex;
      gap: 10px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;

      img {
        position: absolute;
      }

      .left-eclipse {
        left: 0;
        bottom: 0;
      }
      .right-eclipse {
        right: 0;
        top: 0;
      }

      h1 {
        font-size: 32px;
        color: $primary-color;
        font-weight: 600;
        z-index: 2;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        z-index: 2;
      }

      .pink-bg-link-icon {
        display: flex;
        gap: 20px;
        align-items: center;
        padding-block: 10px;
        z-index: 2;

        span {
          font-weight: 400;
        }
        .icon {
          display: flex;
          font-size: 20px;
        }
      }
    }
  }
}

.insurance {
  .container {
    display: flex;
    align-items: center;
    gap: 100px;
    justify-content: space-between;
    padding-block: 100px;

    .insurance-first {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      > h1 {
        font-size: 48px;
        color: $primary-color;
        font-weight: 400;
      }

      > p {
        font-weight: 500;
      }
    }
    .insurance-img-container {
      width: 50%;
      max-width: 500px;
      min-width: 400px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
footer {
  .container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding-block: 50px;

    .link {
      color: $grey-color;
    }

    .footer-first {
      color: $grey-color;
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-width: 300px;

      img {
        width: 162px;
      }
    }
    .footer-second,
    .footer-third,
    .footer-fourth {
      display: flex;
      flex-direction: column;
      gap: 24px;

      h3 {
        font-size: 20px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
          color: $grey-color;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .padding-container {
    padding-inline: 70px;
  }
}

@media screen and (max-width: 950px) {
  .black-text {
    color: $white-color;
    padding: 20px;
    display: block;
    font-size: 14px;
  }

  .general-header,
  .transparent-header {
    .container {
      nav {
        ul {
          position: fixed;
          width: 100%;
          left: 0;
          right: 0;
          background-color: $primary-color;
          flex-direction: column;
          top: 0;
          gap: 0;
          top: -600px;
          transition: all 0.4s ease;
          li {
            width: 100%;
            .link {
              color: $white-color;
              width: 100%;
              padding: 20px;
              display: block;
              font-size: 14px;
            }
          }
        }

        .hamburger-container {
          display: flex;
        }
      }
    }
  }

  .padding-container {
    padding-inline: 50px;
  }

  footer {
    .container {
      flex-direction: column;
    }
  }

  .insurance {
    .container {
      flex-direction: column;

      .insurance-first {
        width: 100%;
      }
      .insurance-img-container {
        width: 100%;
        max-width: 700px;
        height: 400px;

        img {
          border-radius: 32px;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 700px) {
  .facilities-card-container {
    grid-template-columns: 1fr 1fr;
  }

  .health-partner {
    .container {
      .health-main-container {
        flex-direction: column;
        padding-inline: 20px;
        padding-block: 50px;
      }
    }
  }

  .testimonial {
    .container {
      .testimonial-cards {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .facilities-card-container {
    grid-template-columns: 1fr;
  }

  .insurance {
    .container {
      padding-block: 50px;
      .insurance-img-container {
        min-width: 300px;
        height: 300px;
      }
    }
  }
}
