@import "../variables";
.urgent-hero {
  .container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 100px;
    padding-top: 150px;
    background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.5)),
      url("/public/images/urgent-care-full-facility.webp");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white-color;

    position: relative;

    .urgent-hero-content {
      max-width: 700px;
      color: $white-color;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 40px;
      z-index: 2;

      > p {
        line-height: 32px;
        font-size: 20px;
      }

      > span {
        color: $primary-color;
      }

      .urgent-hero-button {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: center;

        .border-btn-link {
          width: 100%;
          border-color: white;
          color: white;
        }
        .pink-bg-link {
          width: 100%;
        }
      }
    }
  }
  // .container {
  //   min-height: 100vh;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding-block: 100px;
  //   padding-top: 150px;

  //   position: relative;

  //   .urgent-hero-content {
  //     max-width: 700px;
  //     color: $white-color;
  //     text-align: center;
  //     display: flex;
  //     flex-direction: column;
  //     gap: 40px;
  //     z-index: 2;

  //     > p {
  //       line-height: 32px;
  //       font-size: 20px;
  //       color: #000000;
  //     }

  //     > span {
  //       color: $primary-color;
  //     }

  //     .urgent-hero-button {
  //       display: flex;
  //       gap: 16px;
  //       width: 100%;
  //       justify-content: center;

  //       .border-btn-link {
  //         width: 100%;
  //       }
  //       .pink-bg-link {
  //         width: 100%;
  //       }
  //     }
  //   }
  // }
}

.visit-us {
  background-color: rgba(255, 247, 249, 1);

  .container {
    display: flex;
    flex-direction: column;
    padding-block: 100px;
    gap: 50px;

    > h1 {
      font-size: 48px;
      color: $primary-color;
      font-weight: 400;
      text-align: center;
    }

    .visit-us-main-container {
      display: flex;
      gap: 70px;
      align-items: center;

      h1 {
        font-size: 40px;
        font-weight: 400;
      }

      p {
        color: $grey-color;
        line-height: 30px;
        font-size: 24px;
        display: flex;
        flex-direction: column;

        > span {
          color: #000000;
        }
      }

      .visit-us-img-container {
        border-radius: 0 80px 0 80px;
        width: 50%;
        max-width: 500px;
        min-width: 400px;

        img {
          border-radius: 0 80px 0 80px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .visit-us-text-content {
        border-left: 5px solid $primary-color;
        padding-left: 10px;
      }
    }
  }
}

.visit-us-facility {
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-block: 100px;
    align-items: center;
    > h1 {
      font-size: 48px;
      color: $primary-color;
      font-weight: 400;
    }

    > p {
      color: $grey-color;
      max-width: 600px;
      text-align: center;
    }

    .visit-facility-card-continer {
      display: flex;
      gap: 40px;
      justify-content: center;
    }
  }
}

.where-to-go {
  .container {
    padding-block: 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    > h1 {
      color: $primary-color;
      font-size: 48px;
      font-weight: 400;
    }
    > p {
      max-width: 600px;
      color: $grey-color;
      text-align: center;
    }

    .where-main-container {
      display: flex;
      gap: 50px;

      .where-card {
        display: flex;
        flex-direction: column;
        gap: 20px;

        > .row {
          display: flex;
          align-items: center;
          gap: 15px;

          .stethoscope-icon-container,
          .cross-icon-container {
            padding: 20px;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            .stethoscope,
            .cross {
              font-size: 30px;
            }
          }

          .stethoscope-icon-container {
            background-color: rgba(255, 251, 246, 1);

            .stethoscope {
              font-size: 30px;
              color: rgba(255, 144, 0, 1);
            }
          }

          .cross-icon-container {
            background-color: rgba(243, 249, 255, 1);
            .cross {
              font-size: 30px;
              color: rgba(18, 100, 181, 1);
            }
          }

          > h3 {
            font-size: 24px;
            font-weight: 400;
          }
        }

        > p {
          color: $grey-color;
        }

        > h4 {
          font-weight: 400;
          font-size: 20px;
        }

        .card-bottom-row {
          display: flex;
          gap: 30px;

          .bottom-row-first,
          .bottom-row-second {
            .time-icon-container,
            .card-icon-container {
              .urgent-timer,
              .urgent-card {
                font-size: 30px;
                color: rgba(255, 144, 0, 1);
              }
              .emergency-timer,
              .emergency-card {
                font-size: 30px;
                color: rgba(18, 100, 181, 1);
              }
            }

            > h5 {
              font-weight: 400;
            }

            > span {
              color: $grey-color;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .urgent-hero {
    .container {
      padding-block: 50px;
    }
  }
  .visit-us {
    .container {
      padding-block: 60px;
      .visit-us-main-container {
        flex-direction: column-reverse;

        .visit-us-img-container {
          width: 100%;
          height: 400px;
          max-width: 700px;

          img {
            width: 100%;
          }
        }
        .visit-us-text-content {
          width: 100%;
        }
      }
    }
  }
  .where-to-go {
    .container {
      .where-main-container {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .urgent-hero {
    .container {
      padding-block: 50px;

      .urgent-hero-content {
        .urgent-hero-button {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  .visit-us {
    .container {
      padding-block: 60px;
      .visit-us-main-container {
        flex-direction: column-reverse;

        .visit-us-img-container {
          width: 100%;
          height: 400px;
          max-width: 700px;

          img {
            width: 100%;
          }
        }
        .visit-us-text-content {
          width: 100%;
        }
      }
    }
  }
  .where-to-go {
    .container {
      .where-main-container {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .visit-us {
    .container {
      .visit-us-main-container {
        .visit-us-img-container {
          min-width: 300px;
          height: 300px;
        }
      }
    }
  }
  .urgent-hero {
    .container {
      .urgent-hero-content {
        .urgent-hero-img-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          img {
            width: 100%;
            max-width: 300px;
          }
        }
      }
    }
  }
}
