@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.primary-hero .container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 100px;
  padding-top: 150px;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.5)), url("/public/images/GFM-clinic.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.primary-hero .container .primary-hero-main-container {
  max-width: 800px;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 2;
}
.primary-hero .container .primary-hero-main-container .row {
  display: flex;
  justify-content: center;
  gap: 40px;
}
.primary-hero .container .primary-hero-main-container .row img {
  height: 120px;
}
.primary-hero .container .primary-hero-main-container > p {
  line-height: 32px;
  font-size: 20px;
  color: #ffffff;
  max-width: 700px;
}

.primary-care-container {
  padding-top: 100px;
}

.app-download .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 100px;
  padding-top: 80px;
  position: relative;
  gap: 50px;
}
.app-download .container > span {
  color: rgb(237, 33, 94);
  font-weight: 600;
  font-size: 30px;
}
.app-download .container > p {
  text-align: center;
  font-size: 18px;
  max-width: 600px;
}
.app-download .container .primary-hero-button {
  display: flex;
  gap: 30px;
  width: 100%;
  justify-content: center;
}
.app-download .container .primary-hero-button #google-icon {
  padding-top: 4px;
}
.app-download .container .primary-hero-button .icons-container {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  align-items: center;
}
.app-download .container .primary-hero-button .icons-container:hover {
  transform: scale(1.1);
}
.app-download .container .primary-hero-button .icons-container span {
  color: rgb(237, 33, 94);
}

.primary-facility .container {
  display: flex;
  padding-block: 100px;
  gap: 50px;
}
.primary-facility .container .facility-text-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding-inline: 70px;
  background-color: rgb(255, 247, 249);
  justify-content: center;
  padding-block: 30px;
}
.primary-facility .container .facility-text-content h1 {
  color: rgb(237, 33, 94);
  font-size: 48px;
  font-weight: 400;
}
.primary-facility .container .facilities-card-container {
  width: 100%;
  display: flex;
  gap: 30px;
  padding-right: 30px;
}

.primary-care-services .container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-block: 100px;
}
.primary-care-services .container > h1 {
  font-size: 48px;
  color: rgb(237, 33, 94);
  font-weight: 400;
  text-align: center;
}
.primary-care-services .container .primary-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 30px;
  -moz-column-gap: 20px;
       column-gap: 20px;
}

.primary-card {
  padding: 32px;
  border-right: 2px solid rgba(235, 235, 235, 0.673);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.primary-card .shield-icon-container .shield-icon {
  font-size: 32px;
  color: rgb(237, 33, 94);
}
.primary-card .shield-icon-container p {
  line-height: 24px;
  font-size: 18px;
}

@media screen and (max-width: 1050px) {
  .primary-hero .container {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 950px) {
  .primary-hero .container .primary-hero-main-container .row {
    flex-direction: column;
    align-items: center;
  }
  .primary-hero .container .primary-hero-main-container .row div {
    max-width: 300px;
  }
  .primary-hero .container .primary-hero-main-container .row div img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .primary-hero .container .primary-hero-main-container .primary-hero-button {
    flex-direction: column;
    align-items: center;
  }
  .primary-facility .container {
    flex-direction: column;
  }
  .primary-facility .container .facilities-card-container {
    padding-inline: 30px;
  }
  .primary-facility .container .facility-text-content {
    width: 100%;
    max-width: 100%;
  }
  .primary-care-services .container .primary-card-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 500px) {
  .primary-facility .container .facilities-card-container {
    padding-inline: 30px;
    flex-direction: column;
  }
  .primary-facility .container .facility-text-content {
    padding-inline: 30px;
  }
  .primary-care-services .container .primary-card-container {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=primary-care.css.map */