@import "./variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-contact-container {
  .team-contact {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("/public/images/contact-hero.jpg");
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-text {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
      color: #ffffff;
      font-size: 20px;
      align-items: center;

      h1 {
        font-size: 48px;
        font-weight: 500;
      }

      p {
        max-width: 600px;
      }
    }
  }

  .contact-section {
    .container {
      padding-block: 100px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      > h1 {
        font-size: 32px;
        color: $primary-color;
      }
      .main-contact-container {
        display: flex;
        gap: 50px;

        .first-container {
          width: 30%;
          display: flex;
          flex-direction: column;
          gap: 40px;

          > p {
            font-weight: 500;
          }

          .pink-container {
            background-color: #fff7f9;
            padding: 30px;
          }
        }
        .second-container {
          width: 70%;
          display: flex;
          flex-direction: column;
          gap: 50px;

          .form-container {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 20px;

            .input-row {
              width: 100%;
              position: relative;
              .contact-icon {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translate(50%, -50%);
              }

              .message {
                top: 18px;
              }

              textarea {
                width: 100%;
                padding: 10px;
                padding-left: 50px;
                border: 1px solid rgba(201, 201, 201, 0.283);
                border-radius: 8px;
              }
            }

            .row {
              display: flex;
              gap: 20px;
              width: 100%;

              input {
                width: 100%;
                padding: 10px;
                padding-block: 20px;
                padding-left: 50px;
                border: 1px solid rgba(201, 201, 201, 0.283);
                border-radius: 8px;
              }
            }
            button {
              border: none;
              background-color: $primary-color;
              padding: 20px;
              outline: none;
              color: $white-color;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .main-contact-container {
    .contact-section {
      .container {
        .main-contact-container {
          flex-direction: column;

          .first-container,
          .second-container {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .main-contact-container {
    .contact-section {
      .container {
        .main-contact-container {
          .second-container {
            .form-container {
              .row {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
