@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.urgent-hero .container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 100px;
  padding-top: 150px;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.5)), url("/public/images/urgent-care-full-facility.webp");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  position: relative;
}
.urgent-hero .container .urgent-hero-content {
  max-width: 700px;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 2;
}
.urgent-hero .container .urgent-hero-content > p {
  line-height: 32px;
  font-size: 20px;
}
.urgent-hero .container .urgent-hero-content > span {
  color: rgb(237, 33, 94);
}
.urgent-hero .container .urgent-hero-content .urgent-hero-button {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
}
.urgent-hero .container .urgent-hero-content .urgent-hero-button .border-btn-link {
  width: 100%;
  border-color: white;
  color: white;
}
.urgent-hero .container .urgent-hero-content .urgent-hero-button .pink-bg-link {
  width: 100%;
}

.visit-us {
  background-color: rgb(255, 247, 249);
}
.visit-us .container {
  display: flex;
  flex-direction: column;
  padding-block: 100px;
  gap: 50px;
}
.visit-us .container > h1 {
  font-size: 48px;
  color: rgb(237, 33, 94);
  font-weight: 400;
  text-align: center;
}
.visit-us .container .visit-us-main-container {
  display: flex;
  gap: 70px;
  align-items: center;
}
.visit-us .container .visit-us-main-container h1 {
  font-size: 40px;
  font-weight: 400;
}
.visit-us .container .visit-us-main-container p {
  color: rgba(25, 24, 37, 0.75);
  line-height: 30px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
}
.visit-us .container .visit-us-main-container p > span {
  color: #000000;
}
.visit-us .container .visit-us-main-container .visit-us-img-container {
  border-radius: 0 80px 0 80px;
  width: 50%;
  max-width: 500px;
  min-width: 400px;
}
.visit-us .container .visit-us-main-container .visit-us-img-container img {
  border-radius: 0 80px 0 80px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.visit-us .container .visit-us-main-container .visit-us-text-content {
  border-left: 5px solid rgb(237, 33, 94);
  padding-left: 10px;
}

.visit-us-facility .container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-block: 100px;
  align-items: center;
}
.visit-us-facility .container > h1 {
  font-size: 48px;
  color: rgb(237, 33, 94);
  font-weight: 400;
}
.visit-us-facility .container > p {
  color: rgba(25, 24, 37, 0.75);
  max-width: 600px;
  text-align: center;
}
.visit-us-facility .container .visit-facility-card-continer {
  display: flex;
  gap: 40px;
  justify-content: center;
}

.where-to-go .container {
  padding-block: 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.where-to-go .container > h1 {
  color: rgb(237, 33, 94);
  font-size: 48px;
  font-weight: 400;
}
.where-to-go .container > p {
  max-width: 600px;
  color: rgba(25, 24, 37, 0.75);
  text-align: center;
}
.where-to-go .container .where-main-container {
  display: flex;
  gap: 50px;
}
.where-to-go .container .where-main-container .where-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.where-to-go .container .where-main-container .where-card > .row {
  display: flex;
  align-items: center;
  gap: 15px;
}
.where-to-go .container .where-main-container .where-card > .row .stethoscope-icon-container,
.where-to-go .container .where-main-container .where-card > .row .cross-icon-container {
  padding: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.where-to-go .container .where-main-container .where-card > .row .stethoscope-icon-container .stethoscope,
.where-to-go .container .where-main-container .where-card > .row .stethoscope-icon-container .cross,
.where-to-go .container .where-main-container .where-card > .row .cross-icon-container .stethoscope,
.where-to-go .container .where-main-container .where-card > .row .cross-icon-container .cross {
  font-size: 30px;
}
.where-to-go .container .where-main-container .where-card > .row .stethoscope-icon-container {
  background-color: rgb(255, 251, 246);
}
.where-to-go .container .where-main-container .where-card > .row .stethoscope-icon-container .stethoscope {
  font-size: 30px;
  color: rgb(255, 144, 0);
}
.where-to-go .container .where-main-container .where-card > .row .cross-icon-container {
  background-color: rgb(243, 249, 255);
}
.where-to-go .container .where-main-container .where-card > .row .cross-icon-container .cross {
  font-size: 30px;
  color: rgb(18, 100, 181);
}
.where-to-go .container .where-main-container .where-card > .row > h3 {
  font-size: 24px;
  font-weight: 400;
}
.where-to-go .container .where-main-container .where-card > p {
  color: rgba(25, 24, 37, 0.75);
}
.where-to-go .container .where-main-container .where-card > h4 {
  font-weight: 400;
  font-size: 20px;
}
.where-to-go .container .where-main-container .where-card .card-bottom-row {
  display: flex;
  gap: 30px;
}
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .time-icon-container .urgent-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .time-icon-container .urgent-card,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .card-icon-container .urgent-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .card-icon-container .urgent-card,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .time-icon-container .urgent-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .time-icon-container .urgent-card,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .card-icon-container .urgent-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .card-icon-container .urgent-card {
  font-size: 30px;
  color: rgb(255, 144, 0);
}
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .time-icon-container .emergency-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .time-icon-container .emergency-card,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .card-icon-container .emergency-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first .card-icon-container .emergency-card,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .time-icon-container .emergency-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .time-icon-container .emergency-card,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .card-icon-container .emergency-timer,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second .card-icon-container .emergency-card {
  font-size: 30px;
  color: rgb(18, 100, 181);
}
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first > h5,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second > h5 {
  font-weight: 400;
}
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-first > span,
.where-to-go .container .where-main-container .where-card .card-bottom-row .bottom-row-second > span {
  color: rgba(25, 24, 37, 0.75);
  font-size: 14px;
}

@media screen and (max-width: 950px) {
  .urgent-hero .container {
    padding-block: 50px;
  }
  .visit-us .container {
    padding-block: 60px;
  }
  .visit-us .container .visit-us-main-container {
    flex-direction: column-reverse;
  }
  .visit-us .container .visit-us-main-container .visit-us-img-container {
    width: 100%;
    height: 400px;
    max-width: 700px;
  }
  .visit-us .container .visit-us-main-container .visit-us-img-container img {
    width: 100%;
  }
  .visit-us .container .visit-us-main-container .visit-us-text-content {
    width: 100%;
  }
  .where-to-go .container .where-main-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 750px) {
  .urgent-hero .container {
    padding-block: 50px;
  }
  .urgent-hero .container .urgent-hero-content .urgent-hero-button {
    flex-direction: column;
    align-items: center;
  }
  .visit-us .container {
    padding-block: 60px;
  }
  .visit-us .container .visit-us-main-container {
    flex-direction: column-reverse;
  }
  .visit-us .container .visit-us-main-container .visit-us-img-container {
    width: 100%;
    height: 400px;
    max-width: 700px;
  }
  .visit-us .container .visit-us-main-container .visit-us-img-container img {
    width: 100%;
  }
  .visit-us .container .visit-us-main-container .visit-us-text-content {
    width: 100%;
  }
  .where-to-go .container .where-main-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .visit-us .container .visit-us-main-container .visit-us-img-container {
    min-width: 300px;
    height: 300px;
  }
  .urgent-hero .container .urgent-hero-content .urgent-hero-img-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .urgent-hero .container .urgent-hero-content .urgent-hero-img-container img {
    width: 100%;
    max-width: 300px;
  }
}/*# sourceMappingURL=urgent-care.css.map */