@import "../variables";

.form-hero{
    min-height: 20vh;
    height: 100%;
    background: url("../../../public/images/forms-hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;


    &::after{
        content: "";
        background-color: rgba(0, 0, 0, 0.412);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
       
    }

    .container{
        width: 100%;
        min-height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 100px;
        padding-top: 170px;
        
   
        .form-hero-main-container{
            max-width: 700px;
            color: $white-color;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 40px;
            z-index: 2;
            align-items: center;
    
            h1{
                font-size: 48px;
                color: $white-color;
                font-weight: 400;
            }

    
        
        }
    }

  

}

.main-form{
    .container{
        padding-block: 50px;
        padding-top: 70px;
        display: flex;
        flex-direction: column;
        gap: 60px;
        >h1{
            font-size: 40px;
            font-weight: 400;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            &::after{
                content: '';
                display: block;
                width: 20%;
                height: 5px;
                background-color: $primary-color;
                text-align: center;
                
            }
        }

        .forms{
            display: flex;
            flex-direction: column;
            gap: 50px;
            .form{
                display: flex;
                flex-direction: column;
                gap: 20px;

                h2{
                    font-size: 24px;
                    font-weight: 400;
            display: flex;
            flex-direction: column;
            

            &::after{
                content: '';
                display: block;
                width: 40px;
                height: 5px;
                background-color: $primary-color;
                
                
            }
                }
            }
        }
    }
    
}