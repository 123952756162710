@import "../variables";

.home-header {
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  .nav-info {
    background-color: $white-color;
    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-block: 20px;
      align-items: center;

      img {
        width: 162px;
      }

      .contact-info {
        display: flex;
        gap: 50px;
        align-items: center;
        > div {
          display: flex;
          gap: 10px;
          align-items: center;
          .icon {
            color: $primary-color;
          }

          p {
            color: $grey-color;
          }
        }
      }
      .hamburger-container,
      .close-hamburger-container {
        padding: 10px;
        border: 2px solid $grey-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        display: none;

        .hamburger {
          font-size: 24px;
          color: $grey-color;
        }
        .close-hamburger {
          font-size: 24px;
          color: $grey-color;
        }
      }
    }
  }

  nav {
    background-color: $primary-color;
    .container {
      width: 100%;

      ul {
        display: flex;
        padding-block: 20px;
        justify-content: center;
        gap: 30px;

        li {
          .link {
            color: $white-color;
          }
        }
      }
    }
  }
}
.home-hero {
  min-height: 100vh;
  height: 100%;
  background: url("../../../public/images/urgent-care-facility.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-block: 100px;

    .hero-content {
      max-width: 700px;
      color: $white-color;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 40px;
      z-index: 2;

      .hero-logo {
        background-color: rgb(255, 255, 255);
        padding: 1rem;
        overflow: hidden;
        border-radius: 12px;
        img {
          width: 100%;
          height: auto;
        }
      }

      > p {
        line-height: 32px;
        font-size: 20px;
        span {
          color: $primary-color;
        }
      }

      .hero-button {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.offer {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-block: 100px;
    padding-bottom: 0;

    > h1 {
      font-size: 48px;
      color: $primary-color;
      font-weight: 400;
      text-align: center;
    }

    .offer-main {
      display: flex;
      gap: 70px;

      .offer-img-container {
        border-radius: 0 80px 0 80px;
        width: 45%;
        max-width: 500px;
        min-width: 400px;
        height: 200px;

        img {
          border-radius: 0 80px 0 80px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .offer-text-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-width: 300px;
        width: 55%;

        > h1 {
          font-size: 40px;
          font-weight: 400;
        }

        > p {
          color: $grey-color;
        }

        .styled-text {
          border-left: 5px solid $primary-color;
          padding-left: 20px;
        }
      }
    }
  }
}

.primary-care {
  .container {
    width: 100%;
    display: flex;
    gap: 70px;
    align-items: center;
    padding-block: 100px;

    .primary-care-text-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;

      > p {
        color: $grey-color;
      }

      .primary-care-row {
        display: flex;
        align-items: center;
        gap: 10px;

        .check-icon-container {
          .check-icon {
            color: $primary-color;
            height: 25px;
            width: 25px;
          }
        }
      }
      > h1 {
        font-size: 40px;
        font-weight: 400;
      }
    }

    .primary-care-img-container {
      border-radius: 0 80px 0 80px;
      width: 50%;
      max-width: 500px;
      min-width: 400px;

      img {
        border-radius: 0 80px 0 80px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.occupational {
  .container {
    width: 100%;
    display: flex;
    gap: 70px;
    padding-top: 70px;
    align-items: center;

    .occupational-img-container {
      width: 50%;
      max-width: 500px;
      min-width: 400px;
      height: 400px;

      img {
        border-radius: 0 80px 0 80px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .occupational-text-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
      > h1 {
        font-size: 40px;
        font-weight: 400;
      }

      > p {
        color: $grey-color;
      }

      .arrow-row {
        display: flex;
        gap: 20px;
        align-items: center;

        .arrow-icon-container {
          .arrow-icon {
            font-size: 20px;
            color: $primary-color;
          }
        }
      }
    }
  }
}

.facilities {
  .container {
    display: flex;
    flex-direction: column;
    padding-block: 100px;
    align-items: center;
    gap: 50px;

    > h1 {
      font-size: 48px;
      color: $primary-color;
      font-weight: 400;
      text-align: center;
    }
  }
}

@media screen and (max-width: 950px) {
  .facilities {
    .container {
      padding-block: 60px;
    }
  }
  .offer {
    .container {
      padding-block: 60px;
      .offer-main {
        flex-direction: column-reverse;
        align-items: center;

        .offer-img-container {
          width: 100%;
          max-width: 700px;
          height: 400px;
          min-width: 300px;
        }
        .offer-text-content {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .primary-care {
    .container {
      padding-block: 60px;
      flex-direction: column;
      .primary-care-img-container {
        width: 100%;
        height: 400px;
        max-width: 700px;
        min-width: 300px;
        img {
          width: 100%;
          object-fit: cover;
          max-width: 100%;
        }
      }
      .primary-care-text-content {
        width: 100%;
      }
    }
  }
  .occupational {
    .container {
      padding-block: 60px;
      flex-direction: column-reverse;

      .occupational-img-container {
        width: 100%;
        max-width: 700px;
        height: 400px;
        min-width: 300px;

        img {
          width: 100%;
        }
      }

      .occupational-text-content {
        width: 100%;
      }
    }
  }
}

.navOpen {
  top: 85px !important;
}

.hidden {
  display: none !important;
}
@media screen and (max-width: 850px) {
  .home-header {
    .nav-info {
      .container {
        .contact-info {
          display: none;
        }
        .hamburger-container {
          display: flex;
        }
      }
    }
    nav {
      position: fixed;
      right: 0;
      left: 0;
      height: fit-content;
      background-color: $primary-color;
      top: -600px;
      transition: all 0.4s ease;
      .container {
        padding: 0;
        ul {
          flex-direction: column;
          gap: 0;

          .link {
            width: 100%;
            padding: 20px;
            display: block;
            transition: all 0.4s ease;

            &:hover {
              background-color: rgb(82, 27, 36);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .home-hero {
    .container {
      padding-bottom: 50px;

      .hero-content {
        .hero-button {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .offer {
    .container {
      .offer-main {
        .offer-img-container {
          height: 300px;
        }
      }
    }
  }
  .primary-care {
    .container {
      .primary-care-img-container {
        height: 300px;
      }
    }
  }

  .occupational {
    .container {
      .occupational-img-container {
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .padding-container {
    padding-inline: 20px;
  }
}
