@import "../variables";

.primary-hero {
  .container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 100px;
    padding-top: 150px;
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.5)),
      url("/public/images/GFM-clinic.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .primary-hero-main-container {
      max-width: 800px;
      color: $white-color;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 40px;
      z-index: 2;

      .row {
        display: flex;
        justify-content: center;
        gap: 40px;

        img {
          height: 120px;
        }
      }

      > p {
        line-height: 32px;
        font-size: 20px;
        color: $white-color;

        max-width: 700px;
      }

      // > span {
      //   color: $white-color;
      //   font-weight: 600;
      //   font-size: 24px;
      // }

      // .primary-hero-button {
      //   display: flex;
      //   gap: 30px;
      //   width: 100%;
      //   justify-content: center;

      //   #google-icon {
      //     padding-top: 4px;
      //   }
      //   .icons-container {
      //     display: flex;
      //     flex-direction: column;
      //     transition: transform 0.3s ease-in-out;
      //     background-color: $white-color;
      //     padding-inline: 10px;
      //     border-radius: 8px;

      //     &:hover {
      //       transform: scale(1.1);
      //     }

      //     span {
      //       color: #000000;
      //     }
      //   }
      // }
    }
  }
  // .container {
  //   min-height: 100vh;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding-block: 100px;
  //   padding-top: 150px;
  //   position: relative;

  //   .primary-hero-main-container {
  //     max-width: 800px;
  //     color: $white-color;
  //     text-align: center;
  //     display: flex;
  //     flex-direction: column;
  //     gap: 40px;
  //     z-index: 2;

  //     .row {
  //       display: flex;
  //       justify-content: center;
  //       gap: 40px;
  //     }

  //     > p {
  //       line-height: 32px;
  //       font-size: 20px;
  //       color: #000000;
  //     }
  //   }
  // }
}

.primary-care-container {
  padding-top: 100px;
}
.app-download {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 100px;
    padding-top: 80px;

    position: relative;
    gap: 50px;
    > span {
      color: $primary-color;
      font-weight: 600;
      font-size: 30px;
    }

    > p {
      text-align: center;
      font-size: 18px;
      max-width: 600px;
    }
    .primary-hero-button {
      display: flex;
      gap: 30px;
      width: 100%;
      justify-content: center;

      #google-icon {
        padding-top: 4px;
      }
      .icons-container {
        display: flex;
        flex-direction: column;
        transition: transform 0.3s ease-in-out;
        align-items: center;

        &:hover {
          transform: scale(1.1);
        }

        span {
          color: $primary-color;
        }
      }
    }
  }
}
.primary-facility {
  .container {
    display: flex;
    padding-block: 100px;
    gap: 50px;

    .facility-text-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      max-width: 600px;
      padding-inline: 70px;
      background-color: rgba(255, 247, 249, 1);
      justify-content: center;
      padding-block: 30px;

      h1 {
        color: $primary-color;
        font-size: 48px;
        font-weight: 400;
      }
    }

    .facilities-card-container {
      width: 100%;
      display: flex;
      gap: 30px;
      padding-right: 30px;
    }
  }
}

.primary-care-services {
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-block: 100px;

    > h1 {
      font-size: 48px;
      color: $primary-color;
      font-weight: 400;
      text-align: center;
    }

    .primary-card-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 30px;
      column-gap: 20px;
    }
  }
}
.primary-card {
  padding: 32px;
  border-right: 2px solid rgba(235, 235, 235, 0.673);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .shield-icon-container {
    .shield-icon {
      font-size: 32px;
      color: $primary-color;
    }
    p {
      line-height: 24px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .primary-hero {
    .container {
      padding-bottom: 50px;
    }
  }
}

@media screen and (max-width: 950px) {
  .primary-hero {
    .container {
      .primary-hero-main-container {
        .row {
          flex-direction: column;
          align-items: center;

          div {
            max-width: 300px;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .primary-hero-button {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  .primary-facility {
    .container {
      flex-direction: column;

      .facilities-card-container {
        padding-inline: 30px;
      }
      .facility-text-content {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .primary-care-services {
    .container {
      .primary-card-container {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .primary-facility {
    .container {
      .facilities-card-container {
        padding-inline: 30px;
        flex-direction: column;
      }
      .facility-text-content {
        padding-inline: 30px;
      }
    }
  }
  .primary-care-services {
    .container {
      .primary-card-container {
        grid-template-columns: 1fr;
      }
    }
  }
}
