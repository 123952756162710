@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-contact-container .team-contact {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/public/images/contact-hero.jpg");
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-contact-container .team-contact .img-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  align-items: center;
}
.main-contact-container .team-contact .img-text h1 {
  font-size: 48px;
  font-weight: 500;
}
.main-contact-container .team-contact .img-text p {
  max-width: 600px;
}
.main-contact-container .contact-section .container {
  padding-block: 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.main-contact-container .contact-section .container > h1 {
  font-size: 32px;
  color: rgb(237, 33, 94);
}
.main-contact-container .contact-section .container .main-contact-container {
  display: flex;
  gap: 50px;
}
.main-contact-container .contact-section .container .main-contact-container .first-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.main-contact-container .contact-section .container .main-contact-container .first-container > p {
  font-weight: 500;
}
.main-contact-container .contact-section .container .main-contact-container .first-container .pink-container {
  background-color: #fff7f9;
  padding: 30px;
}
.main-contact-container .contact-section .container .main-contact-container .second-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container .input-row {
  width: 100%;
  position: relative;
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container .input-row .contact-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(50%, -50%);
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container .input-row .message {
  top: 18px;
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container .input-row textarea {
  width: 100%;
  padding: 10px;
  padding-left: 50px;
  border: 1px solid rgba(201, 201, 201, 0.283);
  border-radius: 8px;
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container .row {
  display: flex;
  gap: 20px;
  width: 100%;
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container .row input {
  width: 100%;
  padding: 10px;
  padding-block: 20px;
  padding-left: 50px;
  border: 1px solid rgba(201, 201, 201, 0.283);
  border-radius: 8px;
}
.main-contact-container .contact-section .container .main-contact-container .second-container .form-container button {
  border: none;
  background-color: rgb(237, 33, 94);
  padding: 20px;
  outline: none;
  color: #ffffff;
  border-radius: 8px;
}

@media screen and (max-width: 900px) {
  .main-contact-container .contact-section .container .main-contact-container {
    flex-direction: column;
  }
  .main-contact-container .contact-section .container .main-contact-container .first-container,
  .main-contact-container .contact-section .container .main-contact-container .second-container {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .main-contact-container .contact-section .container .main-contact-container .second-container .form-container .row {
    flex-direction: column;
  }
}/*# sourceMappingURL=contact.css.map */