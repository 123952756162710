@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container .middle-section .container {
  padding-block: 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.main-container .middle-section .container .our-promises {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main-container .middle-section .container .our-promises h1 {
  color: rgb(237, 33, 94);
  font-size: 48px;
  font-weight: 500;
}
.main-container .middle-section .container .promises-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.main-container .middle-section .container .promises-cards .promise-card {
  display: flex;
  gap: 20px;
}
.main-container .middle-section .container .promises-cards .promise-card .icon-container {
  padding: 10px 15px;
  border-radius: 12px;
  background-color: #fff7f9;
  height: -moz-fit-content;
  height: fit-content;
}
.main-container .middle-section .container .promises-cards .promise-card .icon {
  color: rgb(237, 33, 94);
  flex-shrink: 0;
  font-size: 40px;
}
.main-container .middle-section .container .promises-cards .promise-card .text-icon {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main-container .middle-section .container .promises-cards .promise-card .text-icon h3 {
  font-size: 20px;
  font-weight: 500;
}
.main-container .focus .container {
  display: flex;
  gap: 50px;
}
.main-container .focus .container .paragraph {
  background-color: #fff7f9;
  padding-left: 130px;
  padding-right: 50px;
  padding-block: 70px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
}
.main-container .focus .container .paragraph h1 {
  font-size: 32px;
  color: rgb(237, 33, 94);
  font-weight: 500;
}
.main-container .focus .container .paragraph p {
  font-size: 18px;
}
.main-container .focus .container .list {
  width: 50%;
  display: flex;
  align-items: center;
}
.main-container .focus .container .list .main-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.main-container .focus .container .list .main-list #dot {
  color: #6246e5;
}
.main-container .focus .container .list .main-list .first-list,
.main-container .focus .container .list .main-list .second-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.main-container .focus .container .list .main-list .first-list .dot-text,
.main-container .focus .container .list .main-list .second-list .dot-text {
  display: flex;
  align-items: center;
  gap: 10px;
}
.main-container .our-form .container {
  gap: 10px;
  background-color: #ffffff;
}

@media screen and (max-width: 900px) {
  .main-container .middle-section .container .promises-cards {
    grid-template-columns: 1fr;
  }
  .main-container .focus .container {
    flex-direction: column;
  }
  .main-container .focus .container .paragraph {
    width: 100%;
    padding-left: 50px;
  }
  .main-container .focus .container .list {
    width: 100%;
    padding-left: 50px;
  }
}
@media screen and (max-width: 500px) {
  .main-container .middle-section .container .promises-cards .promise-card {
    flex-direction: column;
  }
  .main-container .middle-section .container .promises-cards .promise-card .icon-container {
    width: -moz-fit-content;
    width: fit-content;
  }
  .main-container .focus .container .paragraph {
    padding-left: 20px;
  }
  .main-container .focus .container .list {
    padding-left: 20px;
  }
  .main-container .focus .container .list .main-list {
    flex-direction: column;
  }
}/*# sourceMappingURL=occupational.css.map */