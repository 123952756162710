@import "../variables";

.team-hero {
  background: url("../../../public/images/team-hero.jpg");
}
.career-hero {
  background: url("../../../public/images/career-hero.jpg");
}
.occupational-hero {
  background: url("../../../public/images/occupation-hero.jpg");
}
.team-hero,
.career-hero,
.occupational-hero {
  min-height: 100vh;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 100px;

    .team-hero-main-container,
    .career-hero-main-container,
    .occupational-hero-main-container {
      max-width: 700px;
      color: $white-color;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 40px;
      z-index: 2;
      align-items: center;

      h1 {
        font-size: 48px;
        color: $white-color;
        font-weight: 500;
      }
      > p {
        line-height: 32px;
        font-size: 20px;
      }
    }
  }
}

.card-wrapper {
  .popup-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99998;
    background-color: rgba(0, 0, 0, 0.378);
    cursor: pointer;
  }
  .team-card {
    position: relative;
    width: 100%;
    height: 450px;
    // cursor: pointer;
    overflow: hidden;
    border-radius: 8px;

    .bio-text {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    // &:hover {
    //   .team-card-overlay {
    //     top: 0;
    //   }

    //   .info {
    //     bottom: -200px;
    //     h4 {
    //       font-size: 20px;
    //       font-weight: 500;
    //     }
    //     p {
    //       font-weight: 600;
    //       color: $primary-color;
    //       font-size: 14px;
    //       text-transform: uppercase;
    //     }
    //   }
    // }

    .team-card-overlay {
      position: absolute;
      z-index: 4;
      background-color: rgba(237, 33, 94, 0.8);
      top: 480px;
      height: 100%;
      left: 0;
      right: 0;
      width: 100%;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px;
      gap: 20px;
      transition: all 0.4s ease;

      > .bio-content {
        .bio-text {
          color: $white-color;
          font-size: 16px;
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          overflow: hidden;
          position: relative;
        }
      }

      .links {
        display: flex;
        gap: 15px;
        .read-more {
          outline: none;
          border: none;
          padding: 12px 28px;
          border-radius: 8px;
          color: $primary-color;
          font-weight: 600;
          letter-spacing: 1px;
          font-size: 14px;
          cursor: pointer;
        }
        .linkedin-icon-container {
          display: block;
          .linkedin-icon {
            font-size: 36px;
            color: $white-color;
            height: 100%;
          }
        }
      }
    }

    > img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 8px;
    }

    .info {
      position: absolute;
      z-index: 3;
      background-color: rgba(255, 242, 246, 1);
      width: 100%;
      padding: 16px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 0px 0px 8px 8px;
      transition: all 0.4s ease;
      height: 25%;
      padding-bottom: 40px;

      h4 {
        font-size: 20px;
        font-weight: 500;
      }
      p {
        font-weight: 600;
        color: $primary-color;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  .popup {
    max-width: 800px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 60vh;
    z-index: 99999;
    border-radius: 8px;
    padding: 30px;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: rgba(237, 33, 94, 0.713);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    .row {
      display: flex;
      flex-direction: row;
      gap: 30px;
      color: $white-color;
      align-items: center;
      .img-container {
        height: 100px;
        width: 100px;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }

    .x-icon {
      font-size: 40px;
      color: $white-color;
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;
    }
    > p {
      color: $white-color;
      height: 250px;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(237, 100, 141, 0.558);
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(237, 33, 94, 0.612);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba(237, 33, 94, 0.612);
      }
    }
  }
}

.medical-personnel,
.board-of-directors {
  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-block: 100px;

    > h1 {
      font-size: 48px;
      color: $primary-color;
      text-align: center;
      font-weight: 400;
    }
    .team-card-main-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      h2 {
        font-size: 24px;
        color: $grey-color;
        text-align: center;
      }
    }
    .team-card-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
    }
    .join-our-team-btn {
      text-align: center;
    }
  }
}

@media screen and (max-width: 950px) {
  .medical-personnel,
  .board-of-directors {
    .container {
      .team-card-container {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .card-wrapper {
    .popup {
      max-width: 800px;
      width: 90%;
    }
  }

  .team-card {
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .medical-personnel,
  .board-of-directors {
    .container {
      .team-card-container {
        grid-template-columns: 1fr;
      }
    }
  }
  .card-wrapper {
    .popup {
      .row {
        gap: 10px;
      }
    }
  }
}
