@import "../variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  .middle-section {
    .container {
      padding-block: 100px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .our-promises {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h1 {
          color: $primary-color;
          font-size: 48px;
          font-weight: 500;
        }
      }

      .promises-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        .promise-card {
          display: flex;
          gap: 20px;

          .icon-container {
            padding: 10px 15px;

            border-radius: 12px;
            background-color: #fff7f9;
            height: fit-content;
          }

          .icon {
            color: $primary-color;
            flex-shrink: 0;
            font-size: 40px;
          }

          .text-icon {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h3 {
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .focus {
    .container {
      display: flex;
      gap: 50px;

      .paragraph {
        background-color: #fff7f9;
        padding-left: 130px;
        padding-right: 50px;
        padding-block: 70px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;

        h1 {
          font-size: 32px;
          color: $primary-color;
          font-weight: 500;
        }

        p {
          font-size: 18px;
        }
      }

      .list {
        width: 50%;
        display: flex;
        align-items: center;

        .main-list {
          display: flex;
          // gap: 32px;
          flex-direction: row;
          gap: 20px;

          #dot {
            color: #6246e5;
          }
          .first-list,
          .second-list {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .dot-text {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }

  .our-form {
    .container {
      gap: 10px;
      background-color: $white-color;
    }
  }
}

@media screen and (max-width: 900px) {
  .main-container {
    .middle-section {
      .container {
        .promises-cards {
          grid-template-columns: 1fr;
        }
      }
    }

    .focus {
      .container {
        flex-direction: column;

        .paragraph {
          width: 100%;
          padding-left: 50px;
        }
        .list {
          width: 100%;
          padding-left: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .main-container {
    .middle-section {
      .container {
        .promises-cards {
          .promise-card {
            flex-direction: column;

            .icon-container {
              width: fit-content;
            }
          }
        }
      }
    }
    .focus {
      .container {
        .paragraph {
          padding-left: 20px;
        }
        .list {
          padding-left: 20px;
          .main-list {
            flex-direction: column;
          }
        }
      }
    }
  }
}
