@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.team-hero {
  background: url("../../../public/images/team-hero.jpg");
}

.career-hero {
  background: url("../../../public/images/career-hero.jpg");
}

.occupational-hero {
  background: url("../../../public/images/occupation-hero.jpg");
}

.team-hero,
.career-hero,
.occupational-hero {
  min-height: 100vh;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.team-hero::after,
.career-hero::after,
.occupational-hero::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.team-hero .container,
.career-hero .container,
.occupational-hero .container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 100px;
}
.team-hero .container .team-hero-main-container,
.team-hero .container .career-hero-main-container,
.team-hero .container .occupational-hero-main-container,
.career-hero .container .team-hero-main-container,
.career-hero .container .career-hero-main-container,
.career-hero .container .occupational-hero-main-container,
.occupational-hero .container .team-hero-main-container,
.occupational-hero .container .career-hero-main-container,
.occupational-hero .container .occupational-hero-main-container {
  max-width: 700px;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 2;
  align-items: center;
}
.team-hero .container .team-hero-main-container h1,
.team-hero .container .career-hero-main-container h1,
.team-hero .container .occupational-hero-main-container h1,
.career-hero .container .team-hero-main-container h1,
.career-hero .container .career-hero-main-container h1,
.career-hero .container .occupational-hero-main-container h1,
.occupational-hero .container .team-hero-main-container h1,
.occupational-hero .container .career-hero-main-container h1,
.occupational-hero .container .occupational-hero-main-container h1 {
  font-size: 48px;
  color: #ffffff;
  font-weight: 500;
}
.team-hero .container .team-hero-main-container > p,
.team-hero .container .career-hero-main-container > p,
.team-hero .container .occupational-hero-main-container > p,
.career-hero .container .team-hero-main-container > p,
.career-hero .container .career-hero-main-container > p,
.career-hero .container .occupational-hero-main-container > p,
.occupational-hero .container .team-hero-main-container > p,
.occupational-hero .container .career-hero-main-container > p,
.occupational-hero .container .occupational-hero-main-container > p {
  line-height: 32px;
  font-size: 20px;
}

.card-wrapper .popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.378);
  cursor: pointer;
}
.card-wrapper .team-card {
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  border-radius: 8px;
}
.card-wrapper .team-card .bio-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.card-wrapper .team-card .team-card-overlay {
  position: absolute;
  z-index: 4;
  background-color: rgba(237, 33, 94, 0.8);
  top: 480px;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  gap: 20px;
  transition: all 0.4s ease;
}
.card-wrapper .team-card .team-card-overlay > .bio-content .bio-text {
  color: #ffffff;
  font-size: 16px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}
.card-wrapper .team-card .team-card-overlay .links {
  display: flex;
  gap: 15px;
}
.card-wrapper .team-card .team-card-overlay .links .read-more {
  outline: none;
  border: none;
  padding: 12px 28px;
  border-radius: 8px;
  color: rgb(237, 33, 94);
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  cursor: pointer;
}
.card-wrapper .team-card .team-card-overlay .links .linkedin-icon-container {
  display: block;
}
.card-wrapper .team-card .team-card-overlay .links .linkedin-icon-container .linkedin-icon {
  font-size: 36px;
  color: #ffffff;
  height: 100%;
}
.card-wrapper .team-card > img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.card-wrapper .team-card .info {
  position: absolute;
  z-index: 3;
  background-color: rgb(255, 242, 246);
  width: 100%;
  padding: 16px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 0px 0px 8px 8px;
  transition: all 0.4s ease;
  height: 25%;
  padding-bottom: 40px;
}
.card-wrapper .team-card .info h4 {
  font-size: 20px;
  font-weight: 500;
}
.card-wrapper .team-card .info p {
  font-weight: 600;
  color: rgb(237, 33, 94);
  font-size: 14px;
  text-transform: uppercase;
}
.card-wrapper .popup {
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 60vh;
  z-index: 99999;
  border-radius: 8px;
  padding: 30px;
  overflow: hidden;
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: rgba(237, 33, 94, 0.713);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.card-wrapper .popup .row {
  display: flex;
  flex-direction: row;
  gap: 30px;
  color: #ffffff;
  align-items: center;
}
.card-wrapper .popup .row .img-container {
  height: 100px;
  width: 100px;
}
.card-wrapper .popup .row .img-container img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.card-wrapper .popup .x-icon {
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
.card-wrapper .popup > p {
  color: #ffffff;
  height: 250px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.card-wrapper .popup > p::-webkit-scrollbar {
  width: 5px;
}
.card-wrapper .popup > p::-webkit-scrollbar-track {
  background: rgba(237, 100, 141, 0.558);
}
.card-wrapper .popup > p::-webkit-scrollbar-thumb {
  background: rgba(237, 33, 94, 0.612);
}
.card-wrapper .popup > p::-webkit-scrollbar-thumb:hover {
  background: rgba(237, 33, 94, 0.612);
}

.medical-personnel .container,
.board-of-directors .container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-block: 100px;
}
.medical-personnel .container > h1,
.board-of-directors .container > h1 {
  font-size: 48px;
  color: rgb(237, 33, 94);
  text-align: center;
  font-weight: 400;
}
.medical-personnel .container .team-card-main-container,
.board-of-directors .container .team-card-main-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.medical-personnel .container .team-card-main-container h2,
.board-of-directors .container .team-card-main-container h2 {
  font-size: 24px;
  color: rgba(25, 24, 37, 0.75);
  text-align: center;
}
.medical-personnel .container .team-card-container,
.board-of-directors .container .team-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.medical-personnel .container .join-our-team-btn,
.board-of-directors .container .join-our-team-btn {
  text-align: center;
}

@media screen and (max-width: 950px) {
  .medical-personnel .container .team-card-container,
  .board-of-directors .container .team-card-container {
    grid-template-columns: 1fr 1fr;
  }
  .card-wrapper .popup {
    max-width: 800px;
    width: 90%;
  }
  .team-card {
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .medical-personnel .container .team-card-container,
  .board-of-directors .container .team-card-container {
    grid-template-columns: 1fr;
  }
  .card-wrapper .popup .row {
    gap: 10px;
  }
}/*# sourceMappingURL=team.css.map */