@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.form-hero {
  min-height: 20vh;
  height: 100%;
  background: url("../../../public/images/forms-hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.form-hero::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.412);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.form-hero .container {
  width: 100%;
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 100px;
  padding-top: 170px;
}
.form-hero .container .form-hero-main-container {
  max-width: 700px;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 2;
  align-items: center;
}
.form-hero .container .form-hero-main-container h1 {
  font-size: 48px;
  color: #ffffff;
  font-weight: 400;
}

.main-form .container {
  padding-block: 50px;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.main-form .container > h1 {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.main-form .container > h1::after {
  content: "";
  display: block;
  width: 20%;
  height: 5px;
  background-color: rgb(237, 33, 94);
  text-align: center;
}
.main-form .container .forms {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.main-form .container .forms .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.main-form .container .forms .form h2 {
  font-size: 24px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}
.main-form .container .forms .form h2::after {
  content: "";
  display: block;
  width: 40px;
  height: 5px;
  background-color: rgb(237, 33, 94);
}/*# sourceMappingURL=forms.css.map */